import {createSelector} from '@ngrx/store';
import {measuringUnitFeature, selectAll, selectEntities,} from '../reducers/measuring-unit.reducer';

export const selectAllMeasuringUnits = createSelector(
  measuringUnitFeature.selectMeasuringUnitsState,
  selectAll,
);

export const selectMeasuringUnitEntities = createSelector(
  measuringUnitFeature.selectMeasuringUnitsState,
  selectEntities,
);
