import {createSelector} from '@ngrx/store';
import {ingredientFeature, selectAll, selectEntities,} from '../reducers/ingredient.reducer';

export const selectAllIngredients = createSelector(
  ingredientFeature.selectIngredientsState,
  selectAll,
);

export const selectIngredientEntities = createSelector(
  ingredientFeature.selectIngredientsState,
  selectEntities,
);
