import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Ingredient from '@app/model/ingredient.model';
import DataService from '../data.service';
import IngredientApiService from '@app/services/api/ingredient-api.service';

@Injectable({
  providedIn: 'root'
})
export default class IngredientDataService implements DataService<Ingredient> {
  private apiService: IngredientApiService;

  constructor(apiService: IngredientApiService) {
    this.apiService = apiService;
  }

  public getAll(): Observable<Ingredient[]> {
    return this.apiService
      .getIngredients();
  }

  public save(ingredient: Ingredient): Observable<Ingredient> {
    return this.apiService.createIngredient(ingredient);
  }
}
