import {createActionGroup, emptyProps, props} from '@ngrx/store';
import Recipe from '@app/model/recipe.model';
import RecipeResponseInterface from '@app/services/api/recipe-response-interface';

export const recipeActions = createActionGroup({
    source: 'Recipe',
    events: {
        'Load All Recipes': emptyProps(),
        'Load All Recipes Success': props<{ data: Recipe[] }>(),
        'Load All Recipes V2 Success': props<{ data: RecipeResponseInterface[] }>(),
        'Map All Recipes': props<{ data: RecipeResponseInterface[] }>(),
        'Map All Recipes Success': props<{ data: Recipe[] }>(),
        'Load All Recipes Failure': props<{ error: any }>(),
        'Add Recipe': props<{ data: Recipe }>(),
        'Add Recipe Success': props<{ data: Recipe }>(),
        'Add Recipe Failure': props<{ error: any }>()
    }
});
