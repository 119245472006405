import {createFeature, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import MeasuringUnit from '@app/model/measuring-unit.model';
import {measuringUnitActions} from '../actions/measuring-unit.actions';

export const measuringUnitFeatureKey = 'measuringUnits';

export type MeasuringUnitState = EntityState<MeasuringUnit>;

const adapter = createEntityAdapter<MeasuringUnit>();

export const initialState: MeasuringUnitState = adapter.getInitialState();

const reducer = createReducer(
  initialState,

  on(
    measuringUnitActions.loadAllMeasuringUnits,
    measuringUnitActions.loadAllMeasuringUnitsFailure,
    measuringUnitActions.addMeasuringUnit,
    measuringUnitActions.addMeasuringUnitFailure,
    (state) => state,
  ),
  on(measuringUnitActions.loadAllMeasuringUnitsSuccess, (state, action) =>
    adapter.setAll(action.data, state),
  ),
  on(measuringUnitActions.addMeasuringUnitSuccess, (state, { data: unit }) =>
    adapter.addOne(unit, state),
  ),
);

export const measuringUnitFeature = createFeature({
  name: measuringUnitFeatureKey,
  reducer,
});

export const { selectAll, selectEntities } = adapter.getSelectors();
