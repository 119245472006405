import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Recipe from '@app/model/recipe.model';
import RecipeApiService from '@app/services/api/recipe-api.service';
import RecipeResponseInterface from '@app/services/api/recipe-response-interface';

@Injectable({
  providedIn: 'root'
})
export default class RecipeDataService {
  private apiService: RecipeApiService;

  constructor(apiService: RecipeApiService) {
    this.apiService = apiService;
  }

  public getAll(): Observable<RecipeResponseInterface[]> {
    return this.apiService.getRecipes();
  }

  public save(recipe: Recipe): Observable<Recipe> {
    if (recipe.id !== null) {
      return this.apiService.editRecipe(recipe.id, recipe);
    }

    return this.apiService.createRecipe(recipe);
  }
}
