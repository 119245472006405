import {createSelector} from '@ngrx/store';
import {keywordFeature, selectAll, selectEntities,} from '../reducers/keyword.reducer';

export const selectAllKeywords = createSelector(
  keywordFeature.selectKeywordsState,
  selectAll,
);

export const selectKeywordEntities = createSelector(
  keywordFeature.selectKeywordsState,
  selectEntities,
);

export const selectAllKeywordNames = createSelector(
  selectAllKeywords,
  (entities) => entities.map((entity) => entity.name),
);

export const selectKeywordByName = (name: string) =>
  createSelector(selectAllKeywords, (entities) =>
    entities.find((entity) => entity.name === name),
  );
