import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, tap} from 'rxjs/operators';
import {recipeActions} from '@app/store/actions/recipe.actions';
import {ingredientActions} from '@app/store/actions/ingredient.actions';
import {keywordActions} from '@app/store/actions/keyword.actions';
import {measuringUnitActions} from '@app/store/actions/measuring-unit.actions';
import {appActions} from '@app/store/actions/app.actions';
import {concatLatestFrom} from '@ngrx/operators';
import {Store} from '@ngrx/store';
import {selectMeasuringUnitEntities} from '@app/store/selectors/measuring-unit.selector';
import {selectIngredientEntities} from '@app/store/selectors/ingredient.selector';
import {selectKeywordEntities} from '@app/store/selectors/keyword.selector';
import {combineLatest} from 'rxjs';
import {RecipeMapperService} from '@app/services/mapper/recipe-mapper.service';

@Injectable()
export class AppEffects {
  showInfoOnDataServiceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          recipeActions.addRecipeFailure,
          recipeActions.loadAllRecipesFailure,
          ingredientActions.addIngredientFailure,
          ingredientActions.loadAllIngredientsFailure,
          keywordActions.loadAllKeywordsFailure,
          measuringUnitActions.addMeasuringUnitFailure,
          measuringUnitActions.loadAllMeasuringUnitsFailure,
        ),
        map((action) => action.error),
        tap((error) =>
          this.snackBar.open(error, null, {
            duration: 5 * 1000,
          }),
        ),
      ),
    { dispatch: false },
  );

  loadKeywordData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => keywordActions.loadAllKeywords()),
    ),
  );

  loadIngredientData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => ingredientActions.loadAllIngredients()),
    ),
  );

  loadMeasuringUnitData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => measuringUnitActions.loadAllMeasuringUnits()),
    ),
  );

  loadRecipeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.appInitialised),
      map(() => recipeActions.loadAllRecipes()),
    ),
  );

  waitRelatedResourcesLoaded = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(keywordActions.loadAllKeywordsSuccess)),
      this.actions$.pipe(ofType(measuringUnitActions.loadAllMeasuringUnitsSuccess)),
      this.actions$.pipe(ofType(ingredientActions.loadAllIngredientsSuccess)),
      this.actions$.pipe(ofType(recipeActions.loadAllRecipesV2Success)),
    ]).pipe(
      map((actions) =>
        recipeActions.mapAllRecipes({ data: actions[3].data }),
      ),
    ),
  );

  mapRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.mapAllRecipes),
      concatLatestFrom(() => [
        this.store.select(selectKeywordEntities),
        this.store.select(selectMeasuringUnitEntities),
        this.store.select(selectIngredientEntities),
      ]),
      map(([action, keywords, measuringUnits, ingredients]) =>
        action.data.map((response) =>
          this.recipeMapper.mapResponseToModel(
            response,
            keywords,
            ingredients,
            measuringUnits,
          ),
        ),
      ),
      map((recipes) => recipeActions.mapAllRecipesSuccess({ data: recipes })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly snackBar: MatSnackBar,
    private readonly store: Store,
    private readonly recipeMapper: RecipeMapperService,
  ) {}
}
