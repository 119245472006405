import {createFeature, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {keywordActions} from '../actions/keyword.actions';
import Keyword from '@app/model/keyword.model';

export const keywordFeatureKey = 'keywords';

export type KeywordState = EntityState<Keyword>;

const adapter = createEntityAdapter<Keyword>();

export const initialState: KeywordState = adapter.getInitialState();

const reducer = createReducer(
  initialState,

  on(
    keywordActions.loadAllKeywords,
    keywordActions.loadAllKeywordsFailure,
    keywordActions.addKeyword,
    keywordActions.addKeywordFailure,
    (state) => state,
  ),
  on(keywordActions.addKeywordSuccess, (state, { data: keyword }) =>
    adapter.addOne(keyword, state),
  ),
  on(keywordActions.loadAllKeywordsSuccess, (state, action) =>
    adapter.setAll(action.data, state),
  ),
);

export const keywordFeature = createFeature({
  name: keywordFeatureKey,
  reducer,
});

export const { selectAll, selectEntities } = adapter.getSelectors();
