import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import Recipe from '@app/model/recipe.model';
import {RecipeMapperService} from '@app/services/mapper/recipe-mapper.service';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import RecipeRequestInterface from '@app/services/api/recipe-request-interface';
import RecipeResponseInterface from '@app/services/api/recipe-response-interface';

@Injectable({
  providedIn: "root",
})
export default class RecipeApiService {
  private readonly httpClient: HttpClient;

  private readonly mapper: RecipeMapperService;

  private readonly URL: string;
  private readonly V2URL: string;

  constructor(httpClient: HttpClient, mapper: RecipeMapperService) {
    this.httpClient = httpClient;
    this.mapper = mapper;
    this.URL = environment.apiUrl + '/recipes';

    // [test-api-platform] url for api v2
    this.V2URL = environment.apiV2Url + '/recipes';
  }

  public getRecipes(): Observable<RecipeResponseInterface[]> {
    const url = this.V2URL;

    return this.httpClient.get<RecipeResponseInterface[]>(url);
  }

  public createRecipe(recipe: Recipe): Observable<Recipe> {
    const mappedRecipe = this.mapper.mapModelToRequest(recipe);

    return this.httpClient
      .post<RecipeRequestInterface>(this.URL, mappedRecipe)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(recipe => Recipe.fromRecipeInterface(recipe)),
      );
  }

  public editRecipe(id: number, recipe: Recipe): Observable<Recipe> {
    const mappedRecipe = this.mapper.mapModelToRequest(recipe);

    return this.httpClient
      .put<RecipeRequestInterface>(`${this.URL}/${id}`, mappedRecipe)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(recipe => Recipe.fromRecipeInterface(recipe)),
      );
  }

  public deleteRecipe(id: number): Observable<Recipe> {
    return this.httpClient
      .delete<RecipeRequestInterface>(`${this.URL}/${id}`)
      .pipe(
        map(request => this.mapper.mapRequestToModel(request)),
        map(recipe => Recipe.fromRecipeInterface(recipe))
      );
  }
}
